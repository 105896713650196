<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-markup-table wrap-cells separator="horizontal">
        <tr>
          <td>Nama</td>
          <td>:</td>
          <td v-if="!data.nama">-</td>
          <td v-else>{{ data.nama }}</td>
        </tr>
        <tr>
          <td>jenjang</td>
          <td>:</td>
          <td v-if="!data.jenjang">-</td>
          <td v-else>{{ data.jenjang }}</td>
        </tr>
        <tr>
          <td>tingkat</td>
          <td>:</td>
          <td v-if="!data.tingkat">-</td>
          <td v-else>{{ data.tingkat }}</td>
        </tr>
        <tr>
          <td>Kelas</td>
          <td>:</td>
          <td v-if="!data.nama_kelas">-</td>
          <td v-else>{{ data.nama_kelas }}</td>
        </tr>
        <tr>
          <td>Kamar</td>
          <td>:</td>
          <td v-if="!data.nama_kamar">-</td>
          <td v-else>{{ data.nama_kamar }}</td>
        </tr>
        <tr>
          <td>Asrama</td>
          <td>:</td>
          <td v-if="!data.nama_asrama">-</td>
          <td v-else>{{ data.nama_asrama }}</td>
        </tr>
        <tr>
          <td>Musyrif/ah</td>
          <td>:</td>
          <td v-if="!data.nama_musyrif">-</td>
          <td v-else>{{ data.nama_musyrif }}</td>
        </tr>
        <tr>
          <td>Murabbi Tahfidz</td>
          <td>:</td>
          <td v-if="!data.nama_murabbi_tahfidz">-</td>
          <td v-else>{{ data.nama_murabbi_tahfidz }}</td>
        </tr>
        <tr>
          <td>Murabbi Mentor</td>
          <td>:</td>
          <td v-if="!data.nama_murabbi_mentor">-</td>
          <td v-else>{{ data.nama_murabbi_mentor }}</td>
        </tr>
      </q-markup-table>

      <q-card-actions align="right">
        <q-btn flat color="primary" label="close" @click="onCancelClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {};
      this.$emit("ok", data);
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>
