<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row justify-between q-gutter-md">
          <q-select
            :options="$LIST_JENJANG"
            v-model="selJenjang"
            @input="
              searchTerm = '';
              getTingkat();
            "
            outlined
            label="Jenjang"
            style="min-width: 130px;"
          ></q-select>

          <q-select
            :options="optTingkat"
            v-model="selTingkat"
            label="Pilih Tingkat"
            outlined
            style="min-width: 130px;"
            @input="getList()"
          ></q-select>
          <q-btn
            unelevated
            icon="add"
            label="Tambah Siswa"
            v-can="['AKADEMIK', 'ADMIN']"
            color="primary"
            :to="`/siswaeditdata/-1/${selJenjang}`"
          ></q-btn>
        </div>
        <div class="row">
          <q-btn
            outline
            color="primary"
            label="Tidak Aktif"
            class="q-mr-md"
            :to="'/siswanonaktif/' + selJenjang + '/' + selTingkat"
          >
            <q-badge color="red" floating>{{ nonaktif }}</q-badge>
          </q-btn>
          <q-input
            outlined
            label="Cari Siswa/NISN"
            v-model="searchTerm"
            @input="getList"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        id="table_siswa"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>no</th>
            <th>nisn</th>
            <th>nama</th>
            <th>gender</th>
            <th>sekolah asal</th>
            <th class="text-center">Total : {{ fullListSiswa.length }}</th>
          </tr>
        </thead>
        <tbody v-if="optTingkat.length == 0 || fullListSiswa.length == 0">
          <tr>
            <td colspan="6" class="text-center">
              Tidak ada Siswa pada Jenjang dan Tingkat terpilih
            </td>
          </tr>
        </tbody>
        <tbody class="bg-white" v-else>
          <tr v-for="(val, i) in listSiswa" :key="i">
            <td>{{ (pagination.current - 1) * pagination.limit + i + 1 }}</td>
            <td>{{ val.nisn }}</td>
            <td>{{ val.nama }}</td>
            <td>{{ val.gender }}</td>
            <td>{{ val.sekolah_asal }}</td>
            <td>
              <div class="row justify-center">
                <q-btn
                  flat
                  dense
                  icon="info"
                  @click="showDialogDetail(val)"
                ></q-btn>
                <q-btn
                  flat
                  dense
                  icon="edit"
                  color="secondary"
                  :to="`/siswaeditdata/${val.id}/${selJenjang}`"
                ></q-btn>
                <q-btn
                  flat
                  dense
                  icon="delete_forever"
                  color="negative"
                  @click="
                    selSiswa = val;
                    delGuard = true;
                  "
                ></q-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-section class="row justify-between">
        <q-btn-dropdown
          unelevated
          color="primary"
          label="Download Excell"
          split
          @click="exportExcel"
        >
          <q-list>
            <q-item clickable v-close-popup @click="downloadFormat2('rombel')">
              <q-item-section>
                <q-item-label>Download Format Kelas</q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="downloadFormat2('kamar')">
              <q-item-section>
                <q-item-label>Download Format Kamar</q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="downloadFormat2('musyrif')">
              <q-item-section>
                <q-item-label>Download Format Musyrif</q-item-label>
              </q-item-section>
            </q-item>

            <q-item
              clickable
              v-close-popup
              @click="downloadFormat2('murabbi_tahfidz')"
            >
              <q-item-section>
                <q-item-label>Download Format Murabbi Tahfidz</q-item-label>
              </q-item-section>
            </q-item>

            <q-item
              clickable
              v-close-popup
              @click="downloadFormat2('murabbi_mentor')"
            >
              <q-item-section>
                <q-item-label>Download Format Murabbi Mentor</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-pagination
          v-model="pagination.current"
          :max="pagination.max"
          input
          input-class="text-orange-10"
          @input="getList"
        />
        <q-btn
          outline
          color="primary"
          label="Filter"
          @click="filtering = true"
        ></q-btn>
      </q-card-section>
    </q-card>
    <q-dialog v-model="delGuard" v-if="selSiswa">
      <q-card>
        <q-card-section>
          Tulis "{{ selSiswa.nama }}" tanpa tanda kutip untuk menghapus siswa
          atas nama
          {{ selSiswa.nama }}
          <q-input outlined dense v-model="keyword"></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="close" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="Hapus"
            :disable="keyword.toLowerCase() != selSiswa.nama.toLowerCase()"
            color="negative"
            @click="hapus()"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="filtering" position="bottom">
      <q-card style="width:calc(100vw - 50%)">
        <q-card-section class="column q-gutter-md">
          <div>
            <label class="text-h6">STATUS</label>
            <q-select
              filled
              v-model="selStatus"
              use-chips
              multiple
              emit-value
              map-options
              @new-value="createValue"
              :options="optStatus"
            />
          </div>
          <q-separator></q-separator>
          <div>
            <label class="text-h6">KOTA</label>
            <q-select
              filled
              v-model="selKota"
              use-chips
              multiple
              emit-value
              map-options
              @new-value="createValue"
              :options="optKota"
            />
          </div>
          <q-separator></q-separator>
          <div>
            <label class="text-h6">Status Orang Tua</label>
            <q-select
              filled
              v-model="selAlmarhum"
              use-chips
              multiple
              emit-value
              map-options
              @new-value="createValue"
              :options="optAlmarhum"
            />
          </div>
          <q-separator></q-separator>
          <div>
            <label class="text-h6">Provinsi</label>
            <q-select
              filled
              v-model="selProvinsi"
              use-chips
              multiple
              emit-value
              map-options
              @new-value="createValue"
              :options="optProvinsi"
            >
              <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                  <q-item-section>
                    <q-item-label
                      >{{ scope.opt.label }} ({{
                        scope.opt.banyak
                      }})</q-item-label
                    >
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <q-separator></q-separator>
          <div>
            <label class="text-h6">Kota</label>
            <q-select
              filled
              v-model="selKota"
              use-chips
              multiple
              emit-value
              map-options
              @new-value="createValue"
              :options="optKota"
            >
              <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                  <q-item-section>
                    <q-item-label
                      >{{ scope.opt.label }} ({{
                        scope.opt.banyak
                      }})</q-item-label
                    >
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <q-separator></q-separator>
          <div v-if="selJenjang == 'MA'">
            <label class="text-h6">PEMINATAN</label>
            <q-select
              filled
              v-model="selPeminatan"
              use-chips
              multiple
              emit-value
              map-options
              @new-value="createValue"
              :options="optPeminatan"
            />
          </div>
          <q-separator></q-separator>
          <div>
            <label class="text-h6">PERINGATAN</label>
            <q-select
              filled
              v-model="selPeringatan"
              use-chips
              multiple
              emit-value
              map-options
              @new-value="createValue"
              :options="optPeringatan"
            />
          </div>
          <q-separator></q-separator>
          <div>
            <label class="text-h6">Tahun</label>
            <q-select
              filled
              v-model="selTahun"
              use-chips
              multiple
              emit-value
              map-options
              @new-value="createValue"
              :options="optTahun"
            />
          </div>
        </q-card-section>
        <q-card-actions align="between">
          <q-btn outline color="primary" label="Close" v-close-popup></q-btn>
          <q-btn
            unelevated
            color="primary"
            label="Apply"
            @click="getList"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogSiswaShowDetail from "@/components/DialogSiswaShowDetail";
import XLSX from "xlsx";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
export default {
  data() {
    return {
      selSiswa: null,
      keyword: "",
      delGuard: false,
      filtering: false,

      optStatus: [
        { label: "CUTI", value: "CUTI" },
        { label: "DALAM KELAS", value: "BERJALAN" },
        { label: "TANPA KELAS", value: "SIAP PINDAH" },
      ],
      selStatus: [],

      optKota: [
        { label: "BATAM", value: "BATAM" },
        { label: "JAMBI", value: "JAMBI" },
        { label: "LUBUK LINGGAU", value: "LUBUK LINGGAU" },
      ],
      selKota: [],

      optAlmarhum: [
        { label: "Almarhum Ayah", value: "ayah" },
        { label: "Almarhum Ibu", value: "ibu" },
        { label: "Almarhum Wali", value: "wali" },
      ],
      selAlmarhum: [],

      optPeringatan: [
        { label: "SP 1", value: "SP 1" },
        { label: "SP 2", value: "SP 2" },
        { label: "SP 3", value: "SP 3" },
      ],
      selPeringatan: [],

      optPeminatan: [
        { label: "IPA", value: "IPA" },
        { label: "IPS", value: "IPS" },
        { label: "AGAMA", value: "AGAMA" },
      ],
      selPeminatan: [],

      optTahun: [],
      selTahun: [],

      optKota: [],
      selKota: [],

      optProvinsi: [],
      selProvinsi: [],

      selJenjang: "",
      listSiswa: [],
      fullListSiswa: [],
      searchTerm: "",
      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },

      nonaktif: 0,
      pilTingkat: false,
      optTingkat: [],
      selTingkat: null,
    };
  },
  async mounted() {
    this.selJenjang = this.$route.params.jenjang;
    await this.getTingkat();
  },
  methods: {
    createValue(val, done) {
      if (val.length > 0) {
        const model = (this.model || []).slice();

        val
          .split(/[,;|]+/)
          .map((v) => v.trim())
          .filter((v) => v.length > 0)
          .forEach((v) => {
            if (stringOptions.includes(v) === false) {
              stringOptions.push(v);
            }
            if (model.includes(v) === false) {
              model.push(v);
            }
          });

        done(null);
        this.model = model;
      }
    },
    async hapus() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http.delete(`/siswa/list/deleteinactive/${this.selSiswa.id}`);
      this.getList();
      this.$q.loading.hide();
      this.selSiswa = null;
      this.keyword = "";
    },
    async getTingkat() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let temp = [];
      let resp = await this.$http.get(
        `/siswa/import/gettingkat/${this.selJenjang}`
      );
      for (let item of resp.data) {
        temp.push(item.tingkat);
      }
      temp.unshift("SEMUA TINGKAT");
      this.optTingkat = temp;
      this.selTingkat = this.optTingkat[0];
      this.$q.loading.hide();
      await this.getList();
    },
    showDialogDetail(val) {
      this.$q
        .dialog({
          component: DialogSiswaShowDetail,
          parent: this,
          data: val,
        })
        .onOk((resp) => {});
    },
    async downloadFormat2(tipeFormat) {
      const workbook = new ExcelJS.Workbook();
      const sheetSiswa = workbook.addWorksheet("SISWA");
      const sheetRuang = workbook.addWorksheet("RUANG");
      sheetSiswa.columns = [
        { header: "Id", key: "id", width: 10 },
        { header: "NISN", key: "nisn", width: 15 },
        { header: "Name", key: "name", width: 32 },
        { header: "Gender", key: "gender", width: 10 },
        { header: "Tingkat", key: "tingkat", width: 10 },
        { header: `${tipeFormat} Asal`, key: "asal", width: 25 },
        { header: `${tipeFormat}`, key: "ruang", width: 25 },
      ];

      sheetRuang.columns = [
        { header: "Id", key: "id", width: 10 },
        { header: `${tipeFormat}`, key: "name", width: 25 },
      ];

      // get list siswa
      this.searchTerm = "";
      await this.getList();
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let poolRuang = "";
      // get list ruang
      let resp = null;
      if (
        tipeFormat == "musyrif" ||
        tipeFormat == "murabbi_tahfidz" ||
        tipeFormat == "murabbi_mentor"
      ) {
        resp = await this.$http.get(
          `/siswa/getruang/${this.selJenjang}/is_${tipeFormat}/1`
        );
      } else {
        resp = await this.$http.get(
          `/siswa/getruang/${this.selJenjang}/${tipeFormat}`
        );
      }
      let listRuang = resp.data;

      let fullList = this.fullListSiswa.slice(0);
      if (tipeFormat == "rombel") {
        fullList = fullList.filter((x) => {
          if (this.selTingkat != "SEMUA TINGKAT") {
            return x.tingkat == this.selTingkat;
          } else {
            return x;
          }
        });

        listRuang = listRuang.filter((x) => {
          if (this.selTingkat != "SEMUA TINGKAT") {
            return x.tingkat == this.selTingkat;
          } else {
            return x;
          }
        });
      }

      for (let ruang of listRuang) {
        let row = Object.keys(ruang).map((key) => {
          return ruang[key];
        });
        poolRuang = poolRuang + ruang.nama + ",";
        sheetRuang.addRow(row);
      }

      for (let item of fullList) {
        let carier = {};
        carier.id = item.id;
        carier.nisn = item.nisn;
        carier.nama = item.nama;
        carier.gender = item.gender;
        carier.tingkat = item.tingkat;
        if (tipeFormat == "rombel") {
          carier.kelas = item.nama_kelas;
        } else if (tipeFormat == "kamar") {
          carier.kamar = item.nama_kamar;
        } else if (tipeFormat == "musyrif") {
          carier.musyrif = item.nama_musyrif;
        }

        let row = Object.keys(carier).map((key) => {
          return carier[key];
        });
        sheetSiswa.addRow(row);
      }

      for (let i = 1; i <= listRuang.length; i++) {
        const cell_ruang = sheetSiswa.getCell(`ZZ${i}`);
        cell_ruang.value = listRuang[i - 1].nama;
      }

      sheetSiswa.getColumn("G").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          formulae: [`$ZZ$${1}:$ZZ$${listRuang.length}`],
          // formulae: [`\"${poolRuang}\"`],
          // formulae: listRuang,

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai data di worksheet RUANG",
        };
      });

      // tulis ke file
      const name = `Format ${tipeFormat} ${this.selJenjang} Tingkat ${this.selTingkat}.xlsx`;
      const buffer = await workbook.xlsx.writeBuffer();
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = new Blob([buffer], { type: fileType });
      saveAs(blob, name);
      this.$q.loading.hide();
      // this.pilTingkat = false;
      // this.selTingkat = null;
    },
    async exportExcel() {
      this.searchTerm = "";
      await this.getList();
      let temp = this.fullListSiswa.slice(0);
      console.log(temp);
      let x = [];
      for (let el of temp) {
        delete el.password;
        delete el.id_kamar;
        delete el.id_musyrif;
        delete el.id_murabbi_tahfidz;
        delete el.id_murabbi_mentor;
        if (this.selJenjang != "MA") {
          delete el.peminatan;
        }
        let obj = {
          id: "",
          nik: "",
          nipd: "",
          nisn: "",
          gender: "",
          tanggal_lahir: "",
          anak_ke: "",
          bersaudara: "",
        };
        x.push(Object.assign(obj, el));
      }

      var ws = XLSX.utils.json_to_sheet(x);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SISWA");

      var now = moment().format("DD MMM YYYY");
      XLSX.writeFile(wb, `data siswa ${this.selJenjang} ${now}.xlsx`);
      this.$q.loading.hide();
    },
    async getList() {
      this.$q.loading.show({
        message: "sedang mengambil data",
      });

      let filter = {
        status: this.selStatus,
        almarhum: this.selAlmarhum,
        kota: this.selKota,
        prov: this.selProvinsi,
        peringatan: this.selPeringatan,
        tahun: this.selTahun,
      };

      if (this.selJenjang == "MA") {
        filter.peminatan = this.selPeminatan;
      }

      var src = "%25";
      if (this.searchTerm != "") src = this.searchTerm;

      let offset = (this.pagination.current - 1) * this.pagination.limit;

      await this.$http
        .post(
          `/siswa/list/${this.selJenjang}/${this.selTingkat}/${src}/${this.pagination.limit}/${offset}`,
          filter,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listSiswa = resp.data;
        });

      await this.$http
        .post(
          `/siswa/list/${this.selJenjang}/${this.selTingkat}/${src}`,
          filter,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.fullListSiswa = resp.data;
          this.pagination.max = Math.ceil(
            this.fullListSiswa.length / this.pagination.limit
          );
        });

      let resp = await this.$http.get(
        `/siswa/list/getinactive/${this.selJenjang}/${this.selTingkat}`
      );
      this.nonaktif = resp.data.length;

      resp = await this.$http.get(
        `/siswa/list/getkota/${this.selJenjang}/${this.selTingkat}`
      );
      let objIndex = resp.data.findIndex((obj) => obj.label == "");
      if (objIndex >= 0) {
        resp.data[objIndex].label = "TANPA KOTA";
      }
      this.optKota = resp.data;

      resp = await this.$http.get(
        `/siswa/list/getprov/${this.selJenjang}/${this.selTingkat}`
      );
      objIndex = resp.data.findIndex((obj) => obj.label == "");
      if (objIndex >= 0) {
        resp.data[objIndex].label = "TANPA PROVINSI";
      }
      this.optProvinsi = resp.data;

      resp = await this.$http.get(
        `/siswa/list/gettahun/${this.selJenjang}/${this.selTingkat}`
      );
      objIndex = resp.data.findIndex((obj) => obj.label == "0");
      if (objIndex >= 0) {
        resp.data[objIndex].label = "TANPA TAHUN MASUK";
      }
      this.optTahun = resp.data;

      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
